.main-page-holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: space-between;
    background-color: #e5ffe4;
    height: 100vh
}

.login-container {
    height: 350px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 18px;
    box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.356);
}

.input-fields-container {
    height: 45%;
    margin-top: 35px;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.input-field .MuiOutlinedInput-root {
    border-radius: 10px;
}

.login-button-out {
    width: 100%;
    height: 65px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.116);
    font-family: "Roboto";
    font-weight: 700;
    font-size: 140%;
    transition: 300ms;
}

.login-button {
    width: 100%;
    height: 65px;
    margin: 0px 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-family: "Roboto";
    font-weight: 700;
    transition: 300ms;
}

.login-button-out.active {
    background: rgba(23, 198, 0, 0.7);
    color: black;
}

.login-button-out.inactive {
    background-color: rgba(157, 157, 157, 0.267);
    color: rgba(255, 255, 255, 0.62);
}

.logo-login-page {
    height: 40%;
    color: black;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.logo-image {
    width: 180px;
}